import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { createElemWithClass, appendHTMLChildren } from "../utilities/utils";

export class Helper {
  constructor(elem) {
    this.element = elem;
    this.url;
    this.lightbox;
    this.links = [...elem.querySelectorAll(".lightbox__link")];
    this.images = this.links.map((link) => link.getAttribute("href"));
    this.respondToKeyboard = this.respondToKeyboard.bind(this);
    document.addEventListener("keyup", this.respondToKeyboard);

    this.closeBtn = createElemWithClass({
      elemTag: "button",
      className: ["lightbox__button", "lightbox__button--close"],
    });
    this.nextBtn = createElemWithClass({
      elemTag: "button",
      className: ["lightbox__button", "lightbox__button--next"],
    });
    this.prevBtn = createElemWithClass({
      elemTag: "button",
      className: ["lightbox__button", "lightbox__button--prev"],
    });
    this.lightboxNav = createElemWithClass({
      elemTag: "nav",
      className: "lightbox__navigation",
    });
    this.lightboxContainer = createElemWithClass({
      className: "lightbox__container",
    });
    this.prevBtn.dataset.dir = "prev";
    this.nextBtn.dataset.dir = "next";
  }

  buildLightbox() {
    const lightbox = createElemWithClass({
      className: "lightbox",
    });
    appendHTMLChildren(this.lightboxNav, [this.prevBtn, this.nextBtn]);
    appendHTMLChildren(lightbox, [
      this.closeBtn,
      this.lightboxNav,
      this.lightboxContainer,
    ]);
    return lightbox;
  }

  displayLightbox() {
    this.lightbox = this.buildLightbox();
    document.body.appendChild(this.lightbox);
    window.setTimeout(() => {
      this.lightbox.classList.add("fade-in");
    }, 100);
    disableBodyScroll(document.body);
  }

  loadImage(url) {
    this.url = null;
    const image = new Image();
    const loader = createElemWithClass({
      className: "lightbox__loader",
    });
    this.lightboxContainer.innerHTML = "";
    this.lightboxContainer.appendChild(loader);
    image.onload = () => {
      loader.classList.add("fade-out");
      this.lightboxContainer.appendChild(image);
      window.setTimeout(() => {
        image.classList.add("fade-in");
      }, 50);
      window.setTimeout(() => {
        this.lightboxContainer.removeChild(loader);
      }, 200);
      this.url = url;
    };
    image.classList.add("lightbox__image");
    image.alt = `Colombe — lightbox image`;
    image.src = url;
  }

  goToPrevImage(idx) {
    if (idx === 0) idx = this.images.length;
    this.loadImage(this.images[idx - 1]);
  }
  goToNextImage(idx) {
    if (idx === this.images.length - 1) idx = -1;
    this.loadImage(this.images[idx + 1]);
  }

  goToDesiredImage(e) {
    e.preventDefault();
    if (e.target.classList.contains("lightbox__button")) {
      let idx = this.images.findIndex((i) => i === this.url);
      const btn = e.target;
      const dir = btn.dataset.dir;
      if (!dir) return;
      if (dir === "prev") this.goToPrevImage(idx);
      if (dir === "next") this.goToNextImage(idx);
    }
  }

  respondToKeyboard(e) {
    const lightbox = document.querySelector(".lightbox");
    if (!lightbox) return;
    let idx = this.images.findIndex((i) => i === this.url);
    if (e.key === "Escape") this.closeLightbox(e);
    if (e.key === "ArrowLeft") this.goToPrevImage(idx);
    if (e.key === "ArrowRight") this.goToNextImage(idx);
  }

  closeLightbox(e) {
    e.preventDefault();
    this.lightbox.classList.remove("fade-in");
    enableBodyScroll(document.body);
    window.setTimeout(() => {
      this.lightbox.remove();
    }, 500);
  }

  update() {
    this.links.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        const url = link.getAttribute("href");
        this.displayLightbox();
        this.loadImage(url);
        this.lightbox.addEventListener("click", (e) => {
          if (e.target === this.closeBtn) this.closeLightbox(e);
          this.goToDesiredImage(e);
        });
      });
    });
  }
}
