import { createElemWithClass, appendHTMLChildren } from "../utilities/utils";

export class Helper {
  constructor(elem) {
    this.grid = elem;
    this.parent = this.grid.parentElement;
    this.gridComputedStyle = window.getComputedStyle(this.grid);
  }

  countGridRows() {
    const gridRowCount = this.gridComputedStyle
      .getPropertyValue("grid-template-rows")
      .split(" ").length;
    return gridRowCount;
  }

  getLineHeight() {
    const firstLine = this.grid.querySelector("div");
    const lineHeight = firstLine.getBoundingClientRect().height;
    return lineHeight;
  }

  createLines() {
    const oldGrids = [...this.parent.querySelectorAll(".grid__access--lines")];
    if (oldGrids.length > 0) {
      oldGrids.forEach((grid) => {
        grid.remove();
      });
    }
    const rowsCount = this.countGridRows();
    const rowHeight = this.getLineHeight();
    const linesWrapper = createElemWithClass({
      className: ["grid", "grid__access--lines"],
    });
    linesWrapper.style.setProperty("--rows-count", rowsCount);
    linesWrapper.style.setProperty("--row-height", `${rowHeight / 16}rem`);
    appendHTMLChildren(this.parent, linesWrapper);
    for (let i = 1; i <= parseInt(rowsCount); i++) {
      const line = createElemWithClass({
        className: ["line"],
      });
      appendHTMLChildren(linesWrapper, line);
    }
  }

  update() {
    this.createLines();
  }
}
