import { getRandomFloat } from "../utilities/utils";

class Card {
  constructor(elem, index, wrapperProps) {
    this.card = elem;
    this.index = index;
    this.wrapper = wrapperProps;

    this.cardWidth = this.card.clientWidth;
    this.cardHeight = this.card.clientHeight;
    this.availableWidth = this.wrapper.width - this.cardWidth;
    this.availableHeight = this.wrapper.height - this.cardHeight * -1;
    this.timer = parseInt(800 * ((this.index + 1) * 0.15));

    this.randNumWidth = Math.round(
      Math.random() * (this.wrapper.width / 2 - this.availableWidth / 12)
    );
    this.randNumHeight = Math.round(
      Math.random() * (this.wrapper.height - this.availableHeight / 4)
    );
    this.randNumRotation = getRandomFloat(-10, 10);

    this.active = false;
    this.currentX;
    this.currentY;
    this.initialX;
    this.initialY;
    this.xOffset = this.randNumWidth;
    this.yOffset = this.randNumHeight;
    this.degOffset = this.randNumRotation;

    console.log(this.availableHeight, this.randNumHeight);
  }

  dragStart(event) {
    if (event.type === "touchstart") {
      this.initialX = event.touches[0].clientX - this.xOffset;
      this.initialY = event.touches[0].clientY - this.yOffset;
    } else {
      this.initialX = event.clientX - this.xOffset;
      this.initialY = event.clientY - this.yOffset;
    }

    if (event.target.closest(".hero__picture")) {
      this.active = true;
      this.card.style.cursor = "grabbing";
      this.card.style.zIndex =
        parseInt(window.getComputedStyle(this.card).zIndex) + 1;
    }
  }

  dragEnd(event) {
    this.initialX = this.currentX;
    this.initialY = this.currentY;

    this.active = false;
    this.card.style.cursor = "grab";
  }

  drag(event) {
    if (this.active) {
      event.preventDefault();
      if (event.type === "touchmove") {
        this.currentX = event.touches[0].clientX - this.initialX;
        this.currentY = event.touches[0].clientY - this.initialY;
      } else {
        this.currentX = event.clientX - this.initialX;
        this.currentY = event.clientY - this.initialY;
      }

      this.xOffset = this.currentX;
      this.yOffset = this.currentY;

      this.setTranslate(this.currentX, this.currentY, this.randNumRotation);
    }
  }

  setTranslate(x, y, deg) {
    if (!this.card) return;
    this.card.style.setProperty("--x-pos", `${x}px`);
    this.card.style.setProperty("--y-pos", `${y}px`);
    this.card.style.setProperty("--rotation-ratio", `${deg}deg`);
    if (!this.card.classList.contains("is-randomized"))
      this.card.classList.add("is-randomized");
  }

  initCardPos() {
    setTimeout(() => {
      this.setTranslate(
        this.randNumWidth,
        this.randNumHeight,
        this.randNumRotation
      );
    }, this.timer);
  }

  updateCardPos() {
    this.card.addEventListener("touchstart", (e) => this.dragStart(e), false);
    this.card.addEventListener("touchend", (e) => this.dragEnd(e), false);
    this.card.addEventListener("touchmove", (e) => this.drag(e), false);
    this.card.addEventListener("mousedown", (e) => this.dragStart(e), false);
    this.card.addEventListener("mouseup", (e) => this.dragEnd(e), false);
    this.card.addEventListener("mousemove", (e) => this.drag(e), false);
  }

  update() {
    this.initCardPos();
    this.updateCardPos();
  }
}

export class Helper {
  constructor(elem) {
    this.hero = elem;
    this.landingArea = this.hero.querySelector(".hero__picture-container");
    this.landing = {
      width: this.landingArea.clientWidth,
      height: this.landingArea.clientHeight,
      top: this.landingArea.getBoundingClientRect().top,
      bottom: this.landingArea.getBoundingClientRect().bottom,
      left: this.landingArea.getBoundingClientRect().left,
      right: this.landingArea.getBoundingClientRect().right,
    };
    this.postCards = [...this.landingArea.querySelectorAll(".hero__picture")];
  }

  setCardsPos() {
    this.postCards.forEach((card, idx) => {
      const cardHelper = new Card(card, idx, this.landing);
      cardHelper.update();
    });
  }

  updateCardsPos() {
    this.postCards.forEach((card, idx) => {
      /* card.addEventListener("touchend", (e) => this.dragEnd(card, e), false);
      card.addEventListener("touchmove", (e) => this.drag(card, e), false); */
      /* card.addEventListener("mouseup", (e) => this.dragEnd(card, e), false);
      card.addEventListener("mousemove", (e) => this.drag(card, e), false); */
    });
  }

  update() {
    this.setCardsPos();
  }
}
