import Splide from "@splidejs/splide";
import { lazyLoadImages } from "../utilities/utils";
import * as navbar from "./navbar";
import * as hero from "./hero";
import * as lightbox from "./lightbox";
import * as lines from "./lines";

export class Helper {
  constructor() {
    this.body = document.body;
    this.mainWrapper = document.querySelector("main");
    this.heroSection = this.mainWrapper.querySelector("#hero");
    this.sliders = [...document.querySelectorAll(".splide")];
    this.lightboxGrids = [...document.querySelectorAll(".lightbox__grid")];
    this.images = [...document.querySelectorAll(".image.lazyload")];
    this.imagesObserverOptions = {
      threshold: 0.05,
    };
    this.navbarHelper = new navbar.Helper(this);
    this.heroHelper = new hero.Helper(this.heroSection);
    this.accessGrid = document.querySelector(".grid__access");
  }

  setUpSliders() {
    this.sliders.forEach((slide) => {
      new Splide(slide, {
        type: "loop",
        autoWidth: true,
        height: "26rem",
        breakpoints: {
          640: {
            height: "22rem",
          },
        },
        lazyLoad: "nearby",
        gap: "1rem",
        pagination: false,
        arrows: true,
        autoplay: false,
        drag: false,
        focus: "center",
        trimSpace: true,
      }).mount();
    });
  }

  setUpLightboxes() {
    this.lightboxGrids.forEach((grid) => {
      this.lightboxHelper = new lightbox.Helper(grid);
      this.lightboxHelper.update();
    });
  }

  setUpAccessLines() {
    this.linesHelper = new lines.Helper(this.accessGrid);
    this.linesHelper.update();
  }

  update() {
    this.navbarHelper.update();
    this.heroHelper.update();
    this.setUpSliders();
    this.setUpLightboxes();
    this.setUpAccessLines();
    lazyLoadImages(this.images, this.imagesObserverOptions);
  }
}
