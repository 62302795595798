export class Helper {
  constructor(page) {
    this.page = page;
    this.main = this.page.main;
    this.navbar = document.querySelector(".navbar");
    this.navbarContainer = this.navbar.querySelector(".navbar__container");
    this.navbarWrapper = this.navbar.querySelector(".navbar__wrapper");
    this.navbarMenu = this.navbar.querySelector(".navbar__menu");
    this.links = [...this.navbarContainer.querySelectorAll(".navbar__link")];
    this.sections = [...this.page.mainWrapper.querySelectorAll("section")];
    this.options = {
      rootMargin: "0px",
      threshold: [0.3],
    };
  }

  openMobileMenu() {
    const menuButton = this.navbarMenu.querySelector("[data-menu='menu']");
    this.navbarMenu.addEventListener("click", (e) => {
      if (e.target !== menuButton) return;
      menuButton.classList.toggle("is-active");
      this.navbarWrapper.classList.toggle("is-active");
    });
  }

  setCurrentLink(target) {
    const targetLink = this.links.find(
      (link) => link.getAttribute("data-loc") === target
    );
    this.links.forEach((link) => {
      link.classList.remove("navbar__link--active");
      if (target !== "hero") {
        link.classList.add("bg-light-100");
      } else {
        link.classList.remove("bg-light-100");
      }
    });
    if (!targetLink) return;
    targetLink.classList.add("navbar__link--active");
  }

  observeSections() {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target.id;
          this.setCurrentLink(target);
        }
      });
    }, this.options);

    this.sections.forEach((section) => {
      observer.observe(section);
    });
  }

  update() {
    this.openMobileMenu();
    this.observeSections();
  }
}
