import { createStyleString } from "@capsizecss/core";

export class Helper {
  constructor() {
    this.lnpMetrics = {
      capHeight: 1536,
      ascent: 2474,
      descent: -1400,
      lineGap: 0,
      unitsPerEm: 2048,
    };
  }

  createTypeSettings(className, { fs, ld }) {
    const textClass = createStyleString(className, {
      fontSize: fs,
      leading: ld,
      fontMetrics: this.lnpMetrics,
    });
    return textClass;
  }

  applyClasstoElems(className, elems) {
    elems.forEach((elem) => {
      elem.classList.add(className);
    });
  }

  injectStylesSettings(target, className, { fs = 16, ld = 24 }) {
    const style = document.createElement("style");

    const targets = [...document.body.querySelectorAll(target)];
    const typesetClass = this.createTypeSettings(`typeset__${className}`, {
      fs: fs,
      ld: ld,
    });
    this.applyClasstoElems(`typeset__${className}`, targets);
    style.innerText = typesetClass;
    document.head.appendChild(style);
  }

  update(bp) {
    let titleFs, titleLd, subtitleFs, subtitleLd, textFs, textLd;

    switch (bp) {
      case "xs":
        titleFs = 32;
        titleLd = 32;
        subtitleFs = 22;
        subtitleLd = 22;
        textFs = 16;
        textLd = 16;
        break;
      case "sm":
        titleFs = 40;
        titleLd = 40;
        subtitleFs = 24;
        subtitleLd = 24;
        textFs = 16;
        textLd = 16;
        break;
      default:
        titleFs = 60;
        titleLd = 64;
        subtitleFs = 30;
        subtitleLd = 32;
        textFs = 18;
        textLd = 18;
    }

    this.injectStylesSettings(".access p", "p--access", {
      fs: textFs,
      ld: textLd,
    });
    this.injectStylesSettings(".address__wrapper p", "p--access", {
      fs: textFs,
      ld: textLd,
    });
    this.injectStylesSettings(".address__wrapper a", "p--access", {
      fs: textFs,
      ld: textLd,
    });
    this.injectStylesSettings(".navbar__link", "a--default", {
      fs: textFs,
      ld: textLd,
    });
    this.injectStylesSettings(".footer__link", "a--default", {
      fs: textFs,
      ld: textLd,
    });
    this.injectStylesSettings(".contact__text__block p", "p--contact", {
      fs: textFs,
      ld: textLd,
    });
    this.injectStylesSettings(".script--large", "script", {
      fs: subtitleFs,
      ld: subtitleLd,
    });
    this.injectStylesSettings(".section__title", "title--section", {
      fs: titleFs,
      ld: titleLd,
    });
  }
}
