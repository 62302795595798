import * as fonts from "./helpers/fonts.js";
import * as page from "./helpers/page.js";
import { getBreakpoint } from "./utilities/utils.js";

let timeOut;
let bp = getBreakpoint();

document.addEventListener("DOMContentLoaded", () => {
  const fontsHelper = new fonts.Helper();
  const pageHelper = new page.Helper();
  fontsHelper.update(bp);
  pageHelper.update();

  window.addEventListener(
    "resize",
    () => {
      if (timeOut) {
        window.cancelAnimationFrame(timeOut);
      }
      timeOut = window.requestAnimationFrame(() => {
        bp = getBreakpoint();
        pageHelper.setUpAccessLines();
        fontsHelper.update(bp);
        console.log(bp);
      });
    },
    false
  );
});
