export function createElemWithClass({ elemTag = "div", className }) {
  const elem = document.createElement(elemTag);
  !Array.isArray(className)
    ? elem.classList.add(`${className}`)
    : className.forEach((n) => {
        elem.classList.add(`${n}`);
      });
  return elem;
}

export function appendHTMLChildren(parent, children) {
  !Array.isArray(children)
    ? parent.appendChild(children)
    : children.forEach((child) => {
        parent.appendChild(child);
      });
}

export function getBreakpoint() {
  return window
    .getComputedStyle(document.body, ":before")
    .content.replace(/\"/g, "");
}

export function getRandomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function bpOnResize(timeOut) {
  window.addEventListener("resize", () => {
    if (!timeOut) {
      timeOut = setTimeout(function () {
        timeOut = null;
        bp = getBreakpoint();
        console.log(bp, "hey");
      }, 100);
    }
  });
}

export function lazyLoadImages(images, options) {
  if (images.length === 0) return;
  const imagesObserver = new IntersectionObserver((entries, imagesObserver) => {
    entries.forEach((entry) => {
      const image = entry.target;
      const plchldr = image.parentElement.querySelector(".image__placeholder");
      if (!entry.isIntersecting) return;
      const newSrc = image.getAttribute("data-src");
      const newSrcset = image.getAttribute("data-srcset");
      image.src = newSrc;
      image.srcset = newSrcset;
      image.onload = () => {
        image.classList.remove("lazyload");
        image.classList.add("is-loaded");
        setTimeout(() => {
          plchldr.classList.add("is-loaded");
        }, 100);
        setTimeout(() => {
          plchldr.remove();
        }, 1000);
      };
      imagesObserver.unobserve(image);
    });
  }, options);

  images.forEach((image) => {
    imagesObserver.observe(image);
  });
}
